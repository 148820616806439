import React from 'react';
import '../../App.css';
import Checkout from './Checkout/Checkout'


const Services = () => {
  return (
    <div>
      <Checkout />
    </div>
  )
}

export default Services
