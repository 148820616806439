import React from 'react'
import styled from 'styled-components'

const Policies = () => {
    return (

        <div>
            <h1>Live Arrival Guarantee</h1>
            We guarantee that all livestock will arrive alive and as described. 
            In the event of DOA (Dead On Arrival) we must be contacted within 2 hours of package delivery (UPS posted time), 
            along with clear photos of the dead coral both in the shipping bag and out of bag. 
            In the event that a buyer does not contact Gypsies Reef in the specified 2 hour time frame, 
            GR reserves the right to decline DOA coverage. Such cases will be reviewed at management’s discretion. 
            BUYER MUST BE AT LOCATION TO RECEIVE DELIVERY, NOT DOING SO VOIDS ALL DOA COVERAGE. No exceptions or excuses, no credit without photo. 
            No refunds or returns on Live purchases. Verified DOA’s will be fully credited towards future purchases (minus any shipping charges). 
            Once the livestock is in your tank, we have no control over the conditions, so no warranty is implied past delivery.
            
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1>Shipping Policy</h1>
            All live items are shipped by UPS through UPS priority overnight, Monday- Friday and usually arrive by 12:00pm CDT. 
            Certain remote areas are delivered later in the day. All purchases over $350 are shipped free.
            If free shipping was applied to the order we will deduct all shipping costs from credit.
        </div>
    )
}

export default Policies
